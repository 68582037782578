import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";

const Marketing = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  // Fetch vendor data from the API
  const fetchVendors = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://api.procydia.com/api/vendor/marketing/vendors"
      );
      setVendors(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendors:", error);
      setError("Failed to fetch vendor data. Please try again later.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVendors();
  }, [fetchVendors]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://api.procydia.com/api/business/", formData); // Replace with your API endpoint
      alert("Form submitted successfully!");
      console.log("Saved Business:", response.data);
      setShowPopup(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit form. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="you@example.com"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Contact Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="123-456-7890"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Your message"
                  rows="4"
                  required
                ></textarea>
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded-lg"
                  onClick={() => setShowPopup(false)}
                >
                  Skip
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="p-6 sm:p-10 lg:p-16 bg-gray-50">
        <div className="text-center max-w-3xl mx-auto mb-10">
          <h1 className="text-4xl font-extrabold text-gray-800">
            Marketing Services
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Explore our comprehensive marketing services designed to boost your
            business through innovative online and offline solutions.
          </p>
        </div>

        <div className="container mx-auto">
          {loading ? (
            <p className="text-gray-500 text-center text-lg">Loading vendors...</p>
          ) : error ? (
            <p className="text-red-500 text-center text-lg">{error}</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
              {vendors.map((vendor) => (
                <div
                  key={vendor.id}
                  className="bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <div className="h-40 w-full overflow-hidden rounded-t-lg">
                    <img
                      src={vendor.profilepicture}
                      alt={vendor.companyName || "Vendor Image"}
                      className="h-full w-full object-contain"
                    />
                  </div>
                  <div className="p-4 text-center">
                    <h2 className="text-xl font-semibold text-gray-800">
                      {vendor.companyName}
                    </h2>
                    <p className="text-sm text-gray-600 mt-1">
                      {vendor.address}
                    </p>
                    <button className="mt-4 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors">
                      <a
                        href={`https://procydia.com/profile/${vendor._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                      >
                        View Profile
                      </a>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Marketing;
