import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";

const BlogPost = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the blog post from backend API by ID
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://api.procydia.com/api/blog/blog/${id}`);
        setBlog(response.data);
      } catch (err) {
        setError("Blog not found. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!blog) return <div>Blog Not Found</div>;

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-10 m-20 text-xl">
        <Link to="/blogs" className="text-blue-500 hover:underline mb-4 inline-block">
          &larr; Back to Blogs
        </Link>
        <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
        <p className="text-gray-600 mb-8">
          {new Date(blog.date).toLocaleDateString()}
        </p>
        <ReactMarkdown className="prose">{blog.content}</ReactMarkdown>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
