import React, { useEffect, useState } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const Gallery = () => {
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchImages = async () => {
    // Simulate fetching image data with a delay
    const imageAlbums = [
      {
        title: "Look at the Events Organised by Procydia",
        images: [
          { src: "weddingdecor1.jpeg", alt: "Wedding 1" },
          { src: "weddingdeco2.jpeg", alt: "Wedding 2" },
          { src: "weddingdecor3.jpeg", alt: "Wedding 3" },
          { src: "weddingdecor4.jpeg", alt: "Wedding 4" },
          { src: "weddingdecor5.jpeg", alt: "Wedding 5" },
          { src: "eventdecor1.jpeg", alt: "Event 1" },
          { src: "eventdecor2.jpeg", alt: "Event 2" },
          { src: "eventdecor3.jpeg", alt: "Event 3" },
        ],
      },
    ];

    return new Promise((resolve) => {
      setTimeout(() => resolve(imageAlbums), 2000); // Simulate delay
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      const albumsData = await fetchImages();
      setAlbums(albumsData);
      setLoading(false); // Set loading to false after fetching
    };

    loadImages();
  }, []);

  return (
    <>
      <div className="m-0 p-0">
        <Navbar />
        <div className="container mx-auto p-8">
          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <div className="w-12 h-12 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
              <p className="ml-4 text-blue-500 font-semibold">Loading...</p>
            </div>
          ) : (
            albums.map((album, index) => (
              <div key={index} className="mb-12">
                <h2 className="text-center text-3xl font-bold mb-10 mt-16">{album.title}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {album.images.map((image, imgIndex) => (
                    <div
                      key={imgIndex}
                      className="overflow-hidden rounded-lg shadow-lg cursor-pointer"
                    >
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="w-full h-60 object-cover object-center transform hover:scale-105 transition-transform duration-300"
                        loading="lazy"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Gallery;
