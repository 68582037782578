import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";
import Navbar from "../Navbaar&Footer/Navbar";
import hotel from "../Media/Loactions/hotel.jpeg";
import farmhouse from "../Media/Loactions/farmhouse.jpeg";
import resort from "../Media/Loactions/resort.jpeg";
import BanquetHalls from "../Media/Loactions/banquethalls.jpeg";

const Location = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    state: "",
    venueType: "",
    email: "",
    description: "",
    numberofguests: "",
  });
  const [showPopup, setShowPopup] = useState(false); // For controlling popup display
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      phoneNumber,
      state,
      venueType,
      email,
      description,
      numberofguests,
    } = formData;
    if (
      !name ||
      !phoneNumber ||
      !state ||
      !venueType ||
      !email ||
      !description ||
      !numberofguests
    ) {
      alert("Please fill in all the fields.");
      return;
    }

    try {
      // Make the POST request to your backend API
      const response = await fetch("https://api.procydia.com/api/venue", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          phoneNumber,
          state,
          venueType,
          email,
          description,
          numberofguests,
        }),
      });

      if (response.ok) {
        // Handle successful response
        setShowPopup(true); // Show success popup
        setTimeout(() => {
          navigate("/"); // Redirect to home after 4 seconds
        }, 4000);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      // Handle network errors
      alert(`Error: ${error.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSelectVenue = (venueType) => {
    setFormData((prev) => ({ ...prev, venueType }));
  };

  const venueImages = {
    Hotel: hotel,
    Resort: resort,
    Farmhouse: farmhouse,
    BanquetHalls, // Replace with the actual image path
  };

  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(
          "https://api.procydia.com/api/vendor/entertainment/vendors"
        );
        if (response.data.length > 0) {
          setVendors(response.data);
        }
      } catch (err) {
        // Properly define 'err' here
        console.error("Error fetching vendors:", err); // 'err' instead of 'error'
        // setVendors(sampleDjList); // In case of error, fallback to sample data
      } finally {
        setLoading(false);
      }
    };
    fetchVendors();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container mx-auto mt-10 p-4 md:p-10">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold mb-4">Book Your Venue Now</h1>
          <p className="text-gray-600 text-lg">
            We’re excited to make your event unforgettable! Please fill out the details below, and we’ll get back to you soon.
          </p>
        </div>

        <div className="max-w-4xl mx-auto bg-white p-8 shadow-lg rounded-lg border border-gray-200">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name Input Field */}
            <div>
              <label htmlFor="name" className="block text-sm font-semibold text-gray-700 mb-2">Name</label>
              <input
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                type="text"
                placeholder="Enter your name"
              />
            </div>

            {/* Phone Number Input Field */}
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-semibold text-gray-700 mb-2">Phone Number</label>
              <input
                id="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                type="text"
                placeholder="Enter your phone number"
              />
            </div>

            {/* Region Input Field */}
            <div>
              <label htmlFor="state" className="block text-sm font-semibold text-gray-700 mb-2">Region</label>
              <select
                id="state"
                value={formData.state}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select a region</option>
                <option value="Delhi">Delhi-NCR</option>
                <option value="Faridabad">Faridabad</option>
                <option value="Gurgaon">Gurgaon</option>
                <option value="Noida">Noida</option>
              </select>
            </div>

            {/* Venue Type Selection */}
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">Venue Type</label>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                {["Hotel", "Resort", "Farmhouse", "BanquetHalls"].map((type, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => handleSelectVenue(type)}
                    className={`border rounded-lg p-4 flex flex-col items-center justify-center transition ${
                      formData.venueType === type ? "border-blue-500 shadow-md" : "border-gray-300"
                    }`}
                  >
                    <img src={venueImages[type]} alt={type} className="w-20 h-20 sm:w-32 sm:h-32 mb-2 rounded-lg" />
                    <p className="text-center text-gray-700">{type}</p>
                  </button>
                ))}
              </div>
              {formData.venueType && (
                <p className="mt-4 text-center text-sm text-gray-600">
                  Selected Venue Type: {formData.venueType}
                </p>
              )}
            </div>

            {/* Number of Guests Input Field */}
            <div>
              <label htmlFor="numberofguests" className="block text-sm font-semibold text-gray-700 mb-2">Number of Guests</label>
              <input
                id="numberofguests"
                value={formData.numberofguests}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                type="number"
                placeholder="Enter number of guests"
              />
            </div>

            {/* Email Input Field */}
            <div>
              <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-2">Email</label>
              <input
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                type="email"
                placeholder="Enter your email"
              />
            </div>

            {/* Event Description Input Field */}
            <div>
              <label htmlFor="description" className="block text-sm font-semibold text-gray-700 mb-2">Event Description</label>
              <textarea
                id="description"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="Provide details about your event"
                rows="4"
              />
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button type="submit" className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition">
                Submit
              </button>
            </div>
          </form>
        </div>
      <div className="max-w-full mx-auto p-6">
        <h1 className="text-3xl font-bold text-center mb-8">Venue Services</h1>
        {loading ? (
          <p>Loading vendors...</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
            {vendors.map((vendor) => (
              <div
                key={vendor._id} // Assuming vendor._id is used for the unique key
                className="bg-white shadow-lg rounded-lg overflow-hidden"
              >
                <img
                  src={vendor.profilepicture}
                  alt={vendor.companyName}
                  className="w-full h-48 object-contain"
                />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold">
                    {vendor.companyName}
                  </h2>
                  <p className="text-gray-600">{vendor.ownerName}</p>
                  <div className="mt-4 flex justify-between items-center">
                    <a
                      href={`tel:${vendor.phone}`}
                      className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
                    >
                      Contact Now
                    </a>
                    <a
                      href={`/profile/${vendor._id}`} // Ensure this field is in your API response or generate it accordingly
                      className="text-blue-500 border border-blue-500 py-2 px-4 rounded-md hover:bg-blue-100 transition"
                    >
                      View Profile
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
      {/* Popup after submission */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Details Submitted</h2>
            <p>
              Your details have been submitted successfully! Redirecting to the
              home page...
            </p>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Location;
