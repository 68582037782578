import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// Helper function to shuffle an array and return a subset
const getRandomBlogs = (blogs, count) => {
  const shuffledBlogs = [...blogs].sort(() => 0.5 - Math.random());
  return shuffledBlogs.slice(0, count);
};

const HomeBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blogs from backend API
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("https://api.procydia.com/api/blog/blog");
        setBlogs(getRandomBlogs(response.data, 3)); // Select 3 random blogs
      } catch (err) {
        setError("Failed to load blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="mx-auto p-10 w-full mt-0">
        <h1 className="text-3xl font-bold mb-8 text-center">Explore Our Blogs ✍️📰</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 p-8 gap-5">
        {blogs.map((blog) => (
          <div 
  key={blog.id} 
  className="bg-white shadow-md hover:shadow-xl transition-shadow rounded-lg overflow-hidden"
>
  <Link to={`/blogs/${blog.id}`} className="block group">
    <div className="p-4">
      <div className="flex flex-col md:flex-row md:space-x-4 items-center">
        <img
          src={
            blog.image ||
            "https://s39940.pcdn.co/wp-content/uploads/2023/01/iStock-1383963898.jpg"
          }
          alt={blog.title}
          className="w-full h-48 md:w-32 md:h-32 object-cover group-hover:scale-105 transition-transform duration-300 rounded-lg"
        />
        <div className="flex flex-col mt-4 md:mt-0">
          <h2 className="text-lg md:text-md font-bold text-gray-800 mb-2 group-hover:text-blue-600 transition-colors">
            {blog.title}
          </h2>
          <p className="text-gray-600 text-sm">{blog.excerpt}</p>
        </div>
      </div>
      <p className="text-gray-500 text-xs mt-2 md:mt-1">
        {new Date(blog.date).toLocaleDateString()}
      </p>
    </div>
  </Link>
</div>

  ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBlogs;
