import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { gsap } from "gsap";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import CrewMembersList from "./CrewMembersList";


const Crew = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDescription: "",
    numberOfPeople: "",
    eventDate: "",
  });

  // eslint-disable-next-line
  const [vendors, setVendors] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get("https://api.procydia.com/api/vendor/entertainment/vendors");
        if (response.data.length > 0) {
          setVendors(response.data);
        } 
      } catch (err) {  // Properly define 'err' here
        console.error("Error fetching vendors:", err);  // 'err' instead of 'error'
        // setVendors(sampleDjList); // In case of error, fallback to sample data
      } finally {
        setLoading(false);
      }
    };
    fetchVendors();
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showPopup) {
      gsap.fromTo(
        ".popup-container",
        { scale: 0 },
        { scale: 1, duration: 0.5, ease: "elastic.out(1, 0.3)" }
      );
      gsap.to(".tick-icon", { rotation: 360, duration: 1, ease: "power4.out" });

      setTimeout(() => {
        setShowPopup(false);
        navigate("/");
      }, 3000); // 3 seconds delay
    }
  }, [showPopup, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.procydia.com/api/crew",
        formData
      );

      if (response.status === 201) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className=" justify-center bg-white text-black p-4 sm:p-6 lg:p-10 w-full mt-8 flex flex-col lg:flex-row">
        {/* <div className="lg:w-3/5">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-6 lg:mb-8">
            Choosing the Right Crew for Your Event
          </h1>
          <div className="max-w-4xl mx-auto">
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              The crew behind your event plays a vital role in its success. From setup and technical support to coordination
              and security, a reliable and experienced crew can make all the difference. Here are some important factors to
              consider when selecting a crew for your event.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">1. Experience and Expertise</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              Look for a crew with experience in handling events similar to yours. Their expertise will ensure smooth
              operations and quick problem-solving on the day of the event.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">2. Roles and Responsibilities</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              Clearly define the roles and responsibilities of each crew member. From event coordinators to technicians and
              security personnel, each person should know their duties to ensure a seamless event.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">3. Communication Skills</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              Effective communication is key to a successful event. Ensure that your crew members can communicate clearly
              with each other and with you, and that they are responsive to any changes or issues that may arise.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">4. Professionalism and Reliability</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              Choose a crew known for their professionalism and reliability. They should be punctual, well-prepared, and
              able to handle pressure. Check references and reviews to assess their reputation.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">5. Technical Knowledge</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              For events requiring audio-visual setups, lighting, or other technical elements, ensure that your crew
              includes technicians with the necessary knowledge and skills. They should be able to troubleshoot any issues
              that arise quickly.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">6. Teamwork</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              A well-coordinated crew that works as a team is essential for the smooth execution of your event. Look for
              crew members who have experience working together or who can quickly adapt to working as a unit.
            </p>

            <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">7. Safety and Security</h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-6">
              If your event requires security personnel, ensure they are trained and experienced. The safety of your guests
              should be a top priority, so make sure your crew includes professionals who can handle any emergencies.
            </p>
          </div>
        </div> */}
        <div className="lg:w-2/5 p-4 sm:p-6 lg:p-8 mt-6 lg:mt-0">
          <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center">
            Book Your Crew Now
          </h2>
          <p className="text-center text-gray-600 mb-4">
            We’re excited to make your event unforgettable! Please fill out the
            details below, and we’ll get back to you soon.
          </p>
          <form
            onSubmit={handleSubmit}
            className="space-y-6 bg-gray-100 p-6 sm:p-8 rounded-lg shadow-lg"
          >
            <div>
              <label className="block mb-2 text-lg font-medium">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter your full name"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email address"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Phone:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Event Description:
              </label>
              <textarea
                name="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                required
                placeholder="Briefly describe your event"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Number of People Needed:
              </label>
              <select
                name="numberOfPeople"
                value={formData.numberOfPeople}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                {[...Array(10).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Date of Event:
              </label>
              <input
                type="date"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition-all shadow-md"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="lg:w-3/5 max-w-full mx-auto lg:mt-0  p-4 sm:p-6 lg:p-8 mt-6">
        <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">
        Crew Services
          </h1> 
          <p className="text-center text-gray-600 mb-4">
          Let’s bring this idea to life by collaborating with our talented team of skilled volunteers! Together, we can execute our vision and make a real impact. 🌟🤝✨
          </p>
        <CrewMembersList />
        <div className="justify justify-center mt-10">
            <a
              href="https://forms.gle/gPTYcuVT6qGX1zs96"
              className=" p-2 justify-center bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition-all shadow-md"
            >
              Register as a crew with Procydia 👨‍👨‍👦‍👦🌐
            </a>
            </div>
          {/* <h1 className="text-3xl font-bold text-center mb-8">
            Crew Services
          </h1>
          {loading ? (
            <p>Loading vendors...</p>
          ) 
           : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
    {vendors.map((vendor) => (
    <div
      key={vendor._id}  // Assuming vendor._id is used for the unique key
      className="bg-white shadow-lg rounded-lg overflow-hidden"
    >
      <img
        src={vendor.profilepicture}
        alt={vendor.companyName}
        className="w-full h-48 object-contain"
      />
      <div className="p-6">
        <h2 className="text-2xl font-semibold">{vendor.companyName}</h2>
        <p className="text-gray-600">{vendor.ownerName}</p>
        <div className="mt-4 flex justify-between items-center">
          <a
            href={`tel:${vendor.phone}`}
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
          >
            Contact Now
          </a>
<Link
    to={`/profile/${vendor._id}`} // Ensure this field is in your API response or generate it accordingly
    className="text-blue-500 border border-blue-500 py-2 px-4 rounded-md hover:bg-blue-100 transition"
>
    View Profile
</Link>
        </div>
      </div>
    </div>
  ))}
            </div>
          )} */}

        </div>
      </div>

      {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Details Submitted</h2>
            <p>Your details have been submitted successfully! Redirecting to the home page...</p>
          </div>
        </div>
      )}
      
      <Footer />
    </div>
  );
};

export default Crew;
