import React from 'react';
import { FaFacebookSquare,FaInstagram,FaLinkedin  } from "react-icons/fa";
import { FaEarthAfrica } from "react-icons/fa6";

const SocialSection = () => {
  const socialLinks = [
    {
      name: <FaFacebookSquare className='text-3xl text-white'/>,
      names: "Facebook",
      url: 'https://facebook.com/procydia',
      bgColor:"bg-blue-500",
      borderColor: 'border-blue-500',
      images: [
        'https://media.licdn.com/dms/image/v2/D5622AQF8lmQ4tWhb6Q/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1721723869355?e=2147483647&v=beta&t=P3LmV48kCDRqdRLI43BWshnNu1BYlKquqSdW1GczWIE',
        'https://media.licdn.com/dms/image/v2/D5622AQEKE3OKnRjHcw/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1720249617883?e=2147483647&v=beta&t=x8DcGucF4jgSO90C0f4T5tPSW9EnLt3eEfeN0DyHfqM',
        'https://media.licdn.com/dms/image/v2/D5622AQF3PhmdTaot8A/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713766435182?e=2147483647&v=beta&t=cqaB-O7hrnzLQmeLS3Xv6NVkuMUZtxNNEih4LfWnyuE',
        'https://media.licdn.com/dms/image/v2/D5622AQGHeVWpKG_UVQ/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713592220437?e=2147483647&v=beta&t=1mALxSP8ngoFKE9rXIN-8ayoUOZTzOSvdnq7CGDLNgY',
      ],
    },
    {
      names: 'Instagram',
      name: <FaInstagram className='text-3xl text-white'/>,
      url: 'https://instagram.com/procydia',
      borderColor: 'border-pink-500',
      bgColor:"bg-pink-500",
      images: [
        'https://media.licdn.com/dms/image/v2/D5622AQF8lmQ4tWhb6Q/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1721723869355?e=2147483647&v=beta&t=P3LmV48kCDRqdRLI43BWshnNu1BYlKquqSdW1GczWIE',
        'https://media.licdn.com/dms/image/v2/D5622AQEKE3OKnRjHcw/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1720249617883?e=2147483647&v=beta&t=x8DcGucF4jgSO90C0f4T5tPSW9EnLt3eEfeN0DyHfqM',
        'https://media.licdn.com/dms/image/v2/D5622AQF3PhmdTaot8A/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713766435182?e=2147483647&v=beta&t=cqaB-O7hrnzLQmeLS3Xv6NVkuMUZtxNNEih4LfWnyuE',
        'https://media.licdn.com/dms/image/v2/D5622AQGHeVWpKG_UVQ/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713592220437?e=2147483647&v=beta&t=1mALxSP8ngoFKE9rXIN-8ayoUOZTzOSvdnq7CGDLNgY',
      ],
    },
    {
        names: "LinkedIn",
        name: <FaLinkedin className='text-3xl text-white'/>,
      url: 'https://www.linkedin.com/in/procydia-d-27228b322/',
      borderColor: 'border-blue-700',
      bgColor:"bg-blue-700",
      images: [
        'https://media.licdn.com/dms/image/v2/D5622AQF8lmQ4tWhb6Q/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1721723869355?e=2147483647&v=beta&t=P3LmV48kCDRqdRLI43BWshnNu1BYlKquqSdW1GczWIE',
        'https://media.licdn.com/dms/image/v2/D5622AQEKE3OKnRjHcw/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1720249617883?e=2147483647&v=beta&t=x8DcGucF4jgSO90C0f4T5tPSW9EnLt3eEfeN0DyHfqM',
        'https://media.licdn.com/dms/image/v2/D5622AQF3PhmdTaot8A/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713766435182?e=2147483647&v=beta&t=cqaB-O7hrnzLQmeLS3Xv6NVkuMUZtxNNEih4LfWnyuE',
        'https://media.licdn.com/dms/image/v2/D5622AQGHeVWpKG_UVQ/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713592220437?e=2147483647&v=beta&t=1mALxSP8ngoFKE9rXIN-8ayoUOZTzOSvdnq7CGDLNgY',
      ],
    },
    {
        names: 'Website',
        name: <FaEarthAfrica className='text-3xl text-white'/>,
      url: 'https://procydia.com',
      borderColor: 'border-sky-400',
      bgColor:"bg-sky-400",
      images: [
        'https://media.licdn.com/dms/image/v2/D5622AQF8lmQ4tWhb6Q/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1721723869355?e=2147483647&v=beta&t=P3LmV48kCDRqdRLI43BWshnNu1BYlKquqSdW1GczWIE',
        'https://media.licdn.com/dms/image/v2/D5622AQEKE3OKnRjHcw/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1720249617883?e=2147483647&v=beta&t=x8DcGucF4jgSO90C0f4T5tPSW9EnLt3eEfeN0DyHfqM',
        'https://media.licdn.com/dms/image/v2/D5622AQF3PhmdTaot8A/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713766435182?e=2147483647&v=beta&t=cqaB-O7hrnzLQmeLS3Xv6NVkuMUZtxNNEih4LfWnyuE',
        'https://media.licdn.com/dms/image/v2/D5622AQGHeVWpKG_UVQ/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1713592220437?e=2147483647&v=beta&t=1mALxSP8ngoFKE9rXIN-8ayoUOZTzOSvdnq7CGDLNgY',
      ],
    },
  ];

  return (
<div className="flex justify-center items-center p-8">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
    {socialLinks.map((link, index) => (
      <div
        key={index}
        className={`text-center border-4 ${link.borderColor} rounded-lg shadow-lg ${link.bgColor}`}
      >
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold justify-center text-gray-700 hover:text-gray-900 hover:underline mb-2 block"
        >
          <div className="flex text-center text-3xl text-white justify-center">
            {link.name}
          </div>
        </a>
        <div className="grid grid-cols-2">
          {link.images.map((img, imgIndex) => (
            <a
              key={imgIndex}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full aspect-square overflow-hidden border-gray-300"
            >
              <img
                src={img}
                alt={`${link.name} ${imgIndex + 1}`}
                className="w-full h-full object-cover"
              />
            </a>
          ))}
        </div>
      </div>
    ))}
  </div>
</div>

  );
};

export default SocialSection;