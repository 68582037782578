import React, { useState, useEffect } from "react";

const BrandPromo = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const slides = ["Brand1.1.jpg", "Brand1.3.jpg"];

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [slides.length]);

  // Simulate loading process
  useEffect(() => {
    const imagePromises = slides.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = resolve; // Handle errors gracefully
      });
    });

    Promise.all(imagePromises).then(() => {
      setLoading(false); // Mark loading as complete
    });
  }, [slides]);

  return (
    <div id="default-carousel" className="p-8 mt-8 relative rounded-lg w-full" data-carousel="slide">
        <a href="https://procydia.com/profile/674d420b64f210439a5fa958">
      {loading ? (
        // Loading Indicator
        <div className="flex items-center justify-center h-40 sm:h-56 md:h-72 lg:h-96 rounded-lg bg-gray-100">
          <div className="animate-spin rounded-full h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 border-t-2 border-b-2 border-gray-600"></div>
          <span className="ml-4 text-sm sm:text-base md:text-lg text-gray-600">
            Loading...
          </span>
        </div>
      ) : (
        <>
          {/* Carousel Wrapper */}
          <div className="relative h-40 sm:h-56 md:h-72 lg:h-96 overflow-hidden rounded-lg">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`absolute w-full h-full transition-opacity duration-700 ease-in-out ${
                  index === currentSlide ? "opacity-100" : "opacity-0"
                }`}
              >
                <img
                  src={slide}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </div>

          {/* Slider Indicators */}
          {/* <div className="absolute z-30 flex -translate-x-1/2 bottom-3 left-1/2 space-x-2">
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 rounded-full ${
                  index === currentSlide ? "bg-white" : "bg-gray-500"
                }`}
                aria-current={index === currentSlide ? "true" : "false"}
                aria-label={`Slide ${index + 1}`}
                onClick={() => setCurrentSlide(index)}
              ></button>
            ))}
          </div> */}
        </>
      )}
      </a>
      {/* <p className="text-center text-sm font-extralight">Sponsered by @OneSetvision</p> */}
    </div>
  );
};

export default BrandPromo;
