import React from 'react';
import { FaInstagram, FaLinkedin, FaFacebook, FaYoutube } from 'react-icons/fa';

const socialMediaLinks = [
  { name: 'Instagram', icon: <FaInstagram />, link: 'https://www.instagram.com/procydia/', hoverColor: 'hover:text-pink-500' },
  { name: 'LinkedIn', icon: <FaLinkedin />, link: 'https://in.linkedin.com/company/greenlobbyenterprises', hoverColor: 'hover:text-blue-500' },
  { name: 'Facebook', icon: <FaFacebook />, link: 'https://www.facebook.com/profile.php?id=100092634392706', hoverColor: 'hover:text-blue-800' },
  { name: 'YouTube', icon: <FaYoutube />, link: 'https://www.youtube.com/@proceedwiththeidea', hoverColor: 'hover:text-red-500' },
];

const SocialMedia = () => {
  return (
    <div className="flex flex-col items-center mt-12 mb-12">
      <h2 className="text-3xl font-bold mb-8 text-center">Social Media</h2>
      <div className="flex flex-wrap justify-center gap-8 sm:gap-6 md:gap-8 lg:gap-10">
        {socialMediaLinks.map((social) => (
          <a
            key={social.name}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Visit our ${social.name} page`}
            className="flex items-center justify-center w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-gray-50 rounded-full shadow-lg hover:shadow-xl transition-all duration-200 transform hover:scale-110"
          >
            <div className={`text-gray-600 text-3xl sm:text-4xl md:text-5xl lg:text-6xl ${social.hoverColor}`}>
              {social.icon}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMedia;
