import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";


const VendorOnboarding = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    ownerName: "",
    panGst: "",
    productSelection: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    instagram: "",
    facebook: "",
  });

  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!formData.companyName) {
      newErrors.companyName = "Company Name is required";
    } else if (formData.companyName.length < 3) {
      newErrors.companyName = "Company Name must be at least 3 characters long";
    }

    if (!formData.ownerName) {
      newErrors.ownerName = "Owner Name is required";
    } else if (formData.ownerName.length < 3) {
      newErrors.ownerName = "Owner Name must be at least 3 characters long";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    const panGstRegex = /^[A-Z0-9]{10,15}$/;
    if (!formData.panGst) {
      newErrors.panGst = "PAN/GST Number is required";
    } else if (!panGstRegex.test(formData.panGst)) {
      newErrors.panGst =
        "PAN/GST Number must be alphanumeric and 10-15 characters long";
    }

    if (!formData.productSelection) {
      newErrors.productSelection = "Product/Service Selection is required";
    }

    if (!formData.address) {
      newErrors.address = "Address is required";
    } else if (formData.address.length < 10) {
      newErrors.address = "Address must be at least 10 characters long";
    }

    const phoneRegex = /^\d{10}$/;
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be a valid 10-digit number";
    }

    const urlRegex =
      /^(https?:\/\/)?(www\.)?(instagram\.com|facebook\.com)\/[a-zA-Z0-9_.-]+\/?$/;
    if (!formData.instagram) {
      newErrors.instagram = "Instagram profile URL is required";
    } else if (!urlRegex.test(formData.instagram)) {
      newErrors.instagram = "Please enter a valid Instagram profile URL";
    }

    if (!formData.facebook) {
      newErrors.facebook = "Facebook profile URL is required";
    } else if (!urlRegex.test(formData.facebook)) {
      newErrors.facebook = "Please enter a valid Facebook profile URL";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // eslint-disable-next-line
  const calculateProgress = () => {
    const totalFields = 10;
    const filledFields = Object.values(formData).filter(
      (value) => value.trim() !== ""
    ).length;
    return (filledFields / totalFields) * 100;
  };

  useEffect(() => {
    setProgress(calculateProgress());
  }, [formData, calculateProgress]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://api.procydia.com/api/vendor/", 
          formData
        );

        // Destructure the response to get vendor and token
        const { vendor, token } = response.data;

        // Optionally, store the token (e.g., in localStorage)
        localStorage.setItem('token', token);

        const vendorId = vendor._id;
        alert("Form submitted successfully!");
        navigate(`/profile/${vendorId}`);
      } catch (error) {
        console.error(error);
        // Extract and display error message from server if available
        const errorMessage = error.response?.data?.message || "Error submitting form";
        alert(errorMessage);
      }
    } else {
      alert("Please fix the validation errors");
    }
  };
  

  return (
    <>
    <Navbar />
    <div className="flex flex-col items-center mt-16 bg-red-50 p-4 min-h-screen bg-s" style={{ backgroundImage: `url('register-bg.jpg')`}}>
      <div className="bg-white rounded-lg shadow-md p-5 w-full max-w-xl">
        <div className="mb-6">
          <a href="/" className="flex items-center text-blue-600">
            <img className="h-5 mr-2" src="back.png" alt="Back" />
          </a>
        </div>
        <h1 className="text-3xl font-bold text-center mb-4 p-4">
          Register As Vendor At Procydia 🥳 
        </h1>
        {/* <p className="text-red-400 text-2xl text-center mb-4 font-bold">Sign Up to Procydia</p> */}
        <div className="relative w-full h-2 bg-gray-200 rounded mb-4">
          <div
            className="absolute top-0 left-0 h-full bg-red-600 rounded"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="companyName"
            >
              Company Name
            </label>
            <input
              type="text"
              id="companyName"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your company name"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
            {errors.companyName && (
              <p className="text-red-600 text-sm">{errors.companyName}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="ownerName"
            >
              Owner Name
            </label>
            <input
              type="text"
              id="ownerName"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter owner's name"
              value={formData.ownerName}
              onChange={handleChange}
              required
            />
            {errors.ownerName && (
              <p className="text-red-600 text-sm">{errors.ownerName}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="panGst"
            >
              PAN/GST Number
            </label>
            <input
              type="text"
              id="panGst"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter PAN/GST number"
              value={formData.panGst}
              onChange={handleChange}
              required
            />
            {errors.panGst && (
              <p className="text-red-600 text-sm">{errors.panGst}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="productSelection"
            >
              Product/Service Selection
            </label>
            <select
              id="productSelection"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={formData.productSelection}
              onChange={handleChange}
              required
            >
              <option value="">Select Products as per your requirements</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Construction">Construction</option>
              <option value="Freelancer">Freelancer</option>
            </select>
            {errors.productSelection && (
              <p className="text-red-600 text-sm">{errors.productSelection}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && (
              <p className="text-red-600 text-sm">{errors.email}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {errors.password && (
              <p className="text-red-600 text-sm">{errors.password}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="address"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your address"
              value={formData.address}
              onChange={handleChange}
              required
            />
            {errors.address && (
              <p className="text-red-600 text-sm">{errors.address}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="instagram"
            >
              Instagram
            </label>
            <input
              type="url"
              id="instagram"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your Instagram Profile URL"
              value={formData.instagram}
              onChange={handleChange}
              required
            />
            {errors.instagram && (
              <p className="text-red-600 text-sm">{errors.instagram}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="facebook"
            >
              Facebook
            </label>
            <input
              type="url"
              id="facebook"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your Facebook Profile URL"
              value={formData.facebook}
              onChange={handleChange}
              required
            />
            {errors.facebook && (
              <p className="text-red-600 text-sm">{errors.facebook}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="phone"
            >
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && (
              <p className="text-red-600 text-sm">{errors.phone}</p>
            )}
          </div>

          <div className="flex items-center">
            <input type="checkbox" id="terms" className="mr-2" required />
            <label htmlFor="terms" className="text-sm text-gray-700">
              I accept the Terms and Privacy Policy
            </label>
          </div>

          <button
            type="submit"
            className="bg-red-600 text-white w-full p-2 rounded-md"
          >
            Continue
          </button>
        </form>

        <p className="mt-4 text-sm text-gray-600 text-center">
          Already a Vendor,Manage Your Profile?{" "}
          <a href="/login" className="text-blue-600">
            Sign in
          </a>
        </p>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default VendorOnboarding;
