import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Navbaar&Footer/Navbar';
import Footer from '../Navbaar&Footer/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';

const LMapComponent = () => {
  const mapRef = useRef(null);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get('https://api.procydia.com/api/vendor/mblock/vendors');
        setVendors(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching vendors:", err);
        setError("Failed to fetch vendor data.");
        setLoading(false);
      }
    };

    fetchVendors();

    const script = document.createElement('script');
    script.src = "https://apis.mappls.com/advancedmaps/api/080798ab65ac3d686ffa9f64ea4c511a/map_sdk?layer=vector&v=3.0&callback=initMap1";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    window.initMap1 = () => {
      const map = new window.mappls.Map(mapRef.current, {
        center: [28.550688036091895, 77.23464225055018],
        zoom: 17
      });

      new window.mappls.Marker({
        position: [28.550688036091895, 77.23464225055018],
        map: map
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
<div className="bg-gray-50 min-h-screen">
  <Navbar />
  <div className="flex flex-col lg:flex-row h-full lg:h-screen mb-10">
    {/* Left side: Vendor list */}
    <div className="lg:w-2/3 w-full p-4 lg:p-12 overflow-y-auto bg-white shadow-md rounded-lg mx-4 my-6 lg:my-10">
      <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-gray-800">
        List of Vendors
      </h2>

      {/* Show loading state */}
      {loading && <p className="text-gray-600">Loading vendors...</p>}

      {/* Show error if any */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Display vendors when data is fetched and no error */}
      {!loading && !error && (
        <div className="grid grid-cols-1 gap-4 lg:gap-6">
          {vendors.map((vendor, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-4 lg:p-6 flex flex-col lg:flex-row items-center lg:space-x-6 space-y-4 lg:space-y-0"
            >
              <img
                src={vendor.profilepicture}
                alt={vendor.ownerName}
                className="w-16 lg:w-20 h-16 lg:h-20 rounded-full object-cover shadow-md"
              />
              <div className="flex-grow text-center lg:text-left">
                <h3 className="text-lg lg:text-xl font-semibold text-gray-900">
                  {vendor.companyName}
                </h3>
                <h4 className="text-md lg:text-lg text-gray-700">
                  {vendor.productSelection}
                </h4>
                <p className="text-sm text-gray-500">{vendor.address}</p>
                <p className="text-sm text-gray-500">
                  Contact: +91 {vendor.phone}
                </p>
              </div>
              <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-3">
                <Link
                  to={`/profile/${vendor._id}`}
                  className="bg-blue-500 text-white px-3 py-2 lg:px-4 lg:py-2 rounded-lg hover:bg-blue-600 transition duration-300 text-sm lg:text-base"
                >
                  View Profile
                </Link>
                <a
                  href={`https://maps.google.com/?q=${vendor.full_address}`}
                  className="bg-green-500 text-white px-3 py-2 lg:px-4 lg:py-2 rounded-lg hover:bg-green-600 transition duration-300 text-sm lg:text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Directions
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>

    {/* Right side: Map */}
    <div className="lg:w-1/3 w-full p-4 lg:p-12 overflow-y-auto bg-white shadow-md rounded-lg mx-4 my-6 lg:my-10">
      <div id="map" ref={mapRef} className="w-full h-full rounded-lg"></div>
    </div>
  </div>
  <Footer />
</div>

  );
};

export default LMapComponent;
