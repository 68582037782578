import React from "react";
import { Slide } from "pure-react-carousel";

export default function SlideItem({ slide, index }) {
  return (
<Slide index={index} key={slide.id}>
  <a href={`${slide.url}`}>
    <div className="flex flex-shrink-0 relative w-full sm:w-auto aspect-w-16 aspect-h-9">
      <picture>
        {/* Use smaller images for mobile devices */}
        <source
          media="(max-width: 640px)"
          srcSet={`${slide.imgSrc}?w=320&h=200`} // Replace with appropriately resized URLs
        />
        <source
          media="(max-width: 1024px)"
          srcSet={`${slide.imgSrc}?w=640&h=360`}
        />
        <source
          media="(min-width: 1025px)"
          srcSet={`${slide.imgSrc}?w=1280&h=720`}
        />
        {/* Fallback image */}
        <img
          src={`${slide.imgSrc}?w=800&h=450`} // Default size
          alt={slide.title}
          className="object-fit object-center rounded-lg transform transition-transform duration-500 ease-in-out hover:scale-105"
          loading="lazy"
        />
      </picture>
      <div className="bg-gray-800 rounded-lg bg-opacity-30 absolute w-full h-full p-6 flex flex-col justify-end">
        <h2 className="text-xl lg:text-xl leading-4 lg:leading-5 text-white">
          {slide.catalog}
        </h2>
        <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">
          {slide.title}
        </h3>
      </div>
    </div>
  </a>
</Slide>

  );
}
