import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CrewMembersList = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    eventDescription: '',
  });
  const [successMessage, setSuccessMessage] = useState(false);

  const handleCardClick = (member) => {
    setSelectedMember(member);
    setIsFormOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.procydia.com/api/eventmangement/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.contactNumber,
          eventDescription: formData.eventDescription,
          memberId: selectedMember?._id,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Form submitted successfully:', result);

      // Show success message and reset form
      setSuccessMessage(true);
      setIsFormOpen(false);
      setFormData({ name: '', email: '', contactNumber: '', eventDescription: '' });

      // Redirect to home after 4 seconds
      setTimeout(() => {
        // Redirect logic here
        window.location.href = '/crew'; // Change this to your desired route
      }, 4000);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally handle errors here
    }
  };

  const [crewMembers, setCrewMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCrewMembers = async () => {
      try {
        const response = await axios.get('https://api.procydia.com/api/crewmembers/crew-members');
        setCrewMembers(response.data.data); // Adjust according to your API response structure
        setLoading(false);
      } catch (error) {
        setError('Error fetching crew members');
        setLoading(false);
      }
    };

    fetchCrewMembers();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6 bg-gray-100 rounded-lg shadow-lg">
        {crewMembers.map((member) => (
          <div
            key={member._id}
            className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-all duration-800 ease-in-out transform hover:scale-105 cursor-pointer"
            onClick={() => handleCardClick(member)}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-2 text-center">{member.fullName}</h2>
            <div className="text-gray-600 mb-4">
              <p className="text-sm"><span className="font-medium">Age:</span> {member.age}</p>
              <p className="text-sm"><span className="font-medium">Gender:</span> {member.gender}</p>
              <p className="text-sm"><span className="font-medium">Height:</span> {member.height}</p>
              {/* <p className="text-sm"><span className="font-medium">Email:</span> {member.email}</p>
              <p className="text-sm"><span className="font-medium">Phone:</span> {member.phoneNumber}</p> */}
              <p className="text-sm"><span className="font-medium">Highest Qualification:</span> {member.highestQualification}</p>
            </div>
            <div className="mb-4">
              <p className="font-medium text-gray-700">Languages:</p>
              <ul className="list-disc list-inside text-sm text-gray-600">
                {member.languages.map((lang, index) => (
                  <li key={index}>
                    {lang.language} - {Array(lang.rating).fill('🌟').join('')}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="font-medium text-gray-700">Hobbies:</p>
              <p className="text-sm text-gray-600">{member.hobbies.join(', ')}</p>
            </div>
          </div>
          
        ))}
      </div>


      {isFormOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
  <form 
    className="bg-white rounded-lg p-8 shadow-lg max-w-md w-full" 
    onSubmit={handleSubmit}
  >
    <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Contact {selectedMember?.fullName}</h2>
    
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        required
        className="border border-gray-300 rounded-md w-full p-3 focus:outline-none focus:ring focus:ring-blue-300"
      />
    </div>
    
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        required
        className="border border-gray-300 rounded-md w-full p-3 focus:outline-none focus:ring focus:ring-blue-300"
      />
    </div>
    
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-1">Contact Number</label>
      <input
        type="tel"
        name="contactNumber"
        value={formData.contactNumber}
        onChange={handleInputChange}
        required
        className="border border-gray-300 rounded-md w-full p-3 focus:outline-none focus:ring focus:ring-blue-300"
      />
    </div>
    
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-1">Event Description</label>
      <textarea
        name="eventDescription"
        value={formData.eventDescription}
        onChange={handleInputChange}
        required
        className="border border-gray-300 rounded-md w-full p-3 focus:outline-none focus:ring focus:ring-blue-300"
        rows="4"
      />
    </div>
    
    <div className="flex justify-center">
      <button 
        type="submit" 
        className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700 transition duration-200"
      >
        Submit
      </button>
      <button 
        type="button" 
        onClick={() => setIsFormOpen(false)} 
        className="ml-3 bg-gray-300 text-gray-800 py-2 px-6 rounded-md hover:bg-gray-400 transition duration-200"
      >
        Cancel
      </button>
    </div>
  </form>
</div>



      )}

      {successMessage && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 shadow-lg w-96 text-center">
            <h2 className="text-xl mb-4">Submission Successful!</h2>
            <p>Your details have been submitted successfully.</p>
            <p>We will reach you shortly⌛</p>
            <p>You will be redirected shortly.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CrewMembersList;
