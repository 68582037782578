import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";
import "mappls-web-maps";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { FaInstagram, FaLinkedin, FaFacebook, FaYoutube } from "react-icons/fa";
// eslint-disable-next-line
import SocialSection from "./SocialComp";

// Mapping icons to social media names
const socialIcons = {
  instagram: <FaInstagram />,
  linkedin: <FaLinkedin />,
  facebook: <FaFacebook />,
  youtube: <FaYoutube />,
  website: <IoEarth />,
};

// Define hover colors for each social platform
const hoverColor = {
  instagram: "hover:text-pink-500",
  linkedin: "hover:text-blue-500",
  facebook: "hover:text-blue-800",
  youtube: "hover:text-red-500",
  website: "hover:text-black",
};

// Helper function to render stars based on rating
// eslint-disable-next-line
const renderStars = (rating) => {
  return Array.from({ length: 5 }, (_, index) => (
    <span
      key={index}
      className={`text-xl ${index < rating ? "text-yellow-400" : "text-gray-300"
        }`}
    >
      ★
    </span>
  ));
};

// eslint-disable-next-line
const VendorDetails = ({ }) => {
  // eslint-disable-next-line
  const [reviews, setReviews] = useState([]);
  // eslint-disable-next-line
  const [images, setImages] = useState([]);
  const { vendorId } = useParams();
  // eslint-disable-next-line
  const [services, setServices] = useState([]);
  const [vendor, setVendor] = useState({
    companyName: "",
    ownerName: "",
    profilepicture: "",
    backgroundpicture: "",
    address: "",
    phone: "",
    instagram: "",
    facebook: "",
    linkedIn: "",
    email: "",
    webiste: "",
    services: {},
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const review = [
    {
      name: "John Doe",
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
      rating: 5,
      description: "Amazing service and top-notch quality!",
      date: "2024-11-20",
    },
    {
      name: "Jane Smith",
      avatar: "https://randomuser.me/api/portraits/women/2.jpg",
      rating: 4,
      description: "Great experience, but room for improvement.",
      date: "2024-11-15",
    },
    {
      name: "Alice Johnson",
      avatar: "https://randomuser.me/api/portraits/women/3.jpg",
      rating: 5,
      description: "Highly recommended. Exceeded expectations!",
      date: "2024-11-10",
    },
    {
      name: "Bob Lee",
      avatar: "https://randomuser.me/api/portraits/men/4.jpg",
      rating: 3,
      description: "Average service. Could be better.",
      date: "2024-11-05",
    },
  ];

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.procydia.com/api/vendor/${vendorId}`
        );
        setVendor(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Construct social links based on available vendor data
  const socialMediaLinks = [
    { name: "instagram", link: vendor.instagram },
    { name: "linkedin", link: vendor.linkedin },
    { name: "facebook", link: vendor.facebook },
    { name: "youtube", link: vendor.youtube },
    { name: "website", link: vendor.website }
  ].filter((social) => social.link); // Filter out empty links

  return (
    <div>
      <Navbar />
      <div className="relative mt-14 pb-5 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Background Image */}
        <div
          className="h-72 bg-cover bg-center rounded-lg shadow-lg"
          style={{
            backgroundImage: `url(${vendor.backgroundpicture ||
              "https://img.freepik.com/free-vector/happy-tiny-people-near-huge-welcome-word-flat-illustration_74855-10808.jpg?t=st=1723893167~exp=1723896767~hmac=20b404de48d041ab40c66267f6eaa48ab707a5ac6dcd3614a46c65393ccf17c9&w=1060"
              })`,
          }}
        >
          <div className="h-full bg-gradient-to-b from-black via-transparent to-black bg-opacity-70 flex justify-center items-center"></div>
        </div>

        {/* Profile Picture */}
        <div className="relative flex sm:justify-center md:justify-normal ml-20 -mt-16">
          <div className="w-48 h-48 rounded-full bg-gray-200 border-4 border-white overflow-hidden shadow-md">
            <img
              src={vendor.profilepicture || "placeholder.jpg"}
              alt="Profile"
              className="w-full h-full object-contain"
            />
          </div>
        </div>

        {/* Description */}
        <div className="flex flex-col md:flex-row justify-between mt-1 px-4">
          {/* Vendor Info on the Left */}
          <div className="mb-4 md:mb-0 ml-0 md:ml-48 text-center md:text-left">
            <h2 className="text-3xl md:text-4xl font-serif text-gray-800">{vendor.companyName}</h2>
            <h3 className="text-2xl md:text-3xl font-thin text-gray-800">{vendor.ownerName}</h3>
          </div>

          {/* Vendor Details on the Right */}
          <div className="space-y-1 mr-0 md:mr-48 text-center md:text-right">
            {/* Address */}
            <div className="flex flex-col md:flex-row items-center md:items-start gap-3 text-gray-600">
              <FaHome className="text-xl text-gray-500" />
              <a
                href={`https://maps.google.com/?q=${vendor.address}`}
                className="text-lg text-gray-700 hover:text-gray-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                {vendor.address || "Address not available"}
              </a>
            </div>

            {/* Phone */}
            <div className="flex flex-col md:flex-row items-center md:items-start gap-3 text-gray-600">
              <FaPhoneVolume className="text-xl text-gray-500" />
              <a
                href={`tel:${vendor.phone}`}
                className="text-lg text-gray-700 hover:text-gray-900"
              >
                {vendor.phone ? `+91-${vendor.phone}` : "Contact number not available"}
              </a>
            </div>
          </div>
        </div>


      </div>

      <hr className="black" />

      <div className="p-8 pb-0  items-center text-gray-600">
        <h2 className="text-3xl font-serif text-center text-gray-800 mb-8 ">
          About
        </h2>
        <p className="text-center font-thin text-xl">
          {vendor.description ||
            "An organization engaged to facilitate the growth of India's startup ecosystem. We provide best in class Digital and Business Services at all scales. Want to Start a business or Expand an existing business? Procydia is the right destination for you"}
        </p>
      </div>

      {/* Social Media Section */}
      <div className="flex-grow p-8 pb-0 md:p-12 bg-white">
        <h2 className="text-3xl font-serif text-center text-gray-800 mb-8 ">
          Socials
        </h2>
        {/* <SocialSection /> */}
        <div className="flex flex-wrap justify-center gap-6 sm:gap-8 lg:gap-10">
          {socialMediaLinks.map((social) => (
            <a
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              className="group flex items-center justify-center w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24  bg-white border border-gray-600 rounded-full shadow-md"
            >
              <div
                className={`text-${[social.name] || "group-hover:text-gray-600"
                  } text-3xl sm:text-4xl lg:text-5xl transition-all duration-300 ${hoverColor[social.name] || "group-hover:text-gray-600"
                  }`}
              >
                {socialIcons[social.name]}
              </div>
            </a>
          ))}
        </div>
      </div>

      {/* Services Section */}
      <div className="flex-grow p-6 md:p-10 bg-gray-50 shadow-lg rounded-lg">
        <h1 className="text-4xl font-serif text-center text-gray-800 mb-10">
          Our Services
        </h1>

        {vendor.services && vendor.services.length > 0 ? (
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {vendor.services.map((service, index) => (
              <div
                key={service._id || index}
                className="bg-white rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow duration-200 p-6"
              >
                <h3 className="text-lg font-medium text-gray-800 text-center mb-2">
                  {service[0] || "Service Name"}
                </h3>
                <p className="text-sm text-gray-600 text-center">
                  {service[1] || "No additional details provided for this service."}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-500 text-lg">
              No services available for this vendor at the moment.
            </p>
          </div>
        )}
      </div>



      {/* Gallery section */}
      <div className="flex-grow justify-center p-8 md:p-12 bg-gradient-to-b from-gray-50 to-gray-100">
        <h1 className="text-4xl font-serif text-center text-gray-800 mb-10">
          Photos
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {images && images.length > 0
            ? images.map((image, index) => (
              <div
                key={index}
                className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105"
              >
                {/* eslint-disable-next-line  */}
                <img
                  src={image}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-72 rounded-t-lg object-contain"
                />
                <div className="p-4">
                  <p className="text-gray-600 text-sm">Image {index + 1}</p>
                </div>
              </div>
            ))
            : [
              vendor.gallery1 ||
              "https://gofloaters.com/static/c8424225d12c692c9f2aba70797c95a5/47498/point-guide-to-run-an-effective-team-meeting.jpg",
              vendor.gallery2 ||
              "https://2456764.fs1.hubspotusercontent-na1.net/hubfs/2456764/YoungPeopleBusinessMeeting_1200x627.jpg",
              vendor.gallery3 ||
              "https://inovapayroll.com/wp-content/uploads/2022/08/Effective-Meetings-1.png",
              vendor.gallery4 ||
              "https://www.ringcentral.com/au/en/blog/wp-content/uploads/2022/10/a-manager-conducting-a-meeting-in-the-office.jpg",
            ].map((sampleImage, index) => (
              <div
                key={index}
                className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105"
              >
                {/* eslint-disable-next-line  */}
                <img
                  src={sampleImage}
                  alt={`Sample Image ${index + 1}`}
                  className="w-full h-60 object-cover rounded-t-lg"
                />
              </div>
            ))}
        </div>
      </div>

      {/* Reviews section */}
      <div className="p-8">
        <h1 className="text-3xl text-center font-semibold text-gray-800 mb-10">
          Customer Reviews
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {review.map((review, index) => (
            <div
              key={index}
              className="p-6 bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-400 rounded-lg shadow-md transition-transform transform "
            >
              {/* Reviewer Avatar */}
              <div className="flex justify-center mb-4">
                <img
                  src={review.avatar}
                  alt={review.name}
                  className="w-16 h-16 rounded-full border-2 border-gray-200 shadow-sm"
                />
              </div>

              {/* Star Ratings */}
              <div className="flex justify-center mb-4">
                {Array(5)
                  .fill(0)
                  .map((_, starIndex) => (
                    <span
                      key={starIndex}
                      className={
                        starIndex < review.rating
                          ? "text-yellow-500"
                          : "text-gray-300"
                      }
                    >
                      ★
                    </span>
                  ))}
              </div>

              {/* Review Text */}
              <p className="text-gray-700 text-center text-sm mb-3">
                {review.description}
              </p>

              {/* Reviewer Name and Date */}
              <p className="text-gray-600 text-xs text-center font-semibold mb-1">
                - {review.name}
              </p>
              <p className="text-gray-400 text-xs text-center">
                {new Date(review.date).toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>
      </div>


      <Footer />
    </div>
  );
};

export default VendorDetails;
