import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import axios from "axios";

const EventBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blogs from backend API and filter by category
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("https://api.procydia.com/api/blog/blog");
        const eventBlogs = response.data.filter((blog) => blog.category === "events");
        setBlogs(eventBlogs);
      } catch (err) {
        setError("Failed to load blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-8 m-20 mt-2 mb-2">
        <h1 className="text-4xl font-bold mb-8 text-center">Read our blogs on Event Management</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog) => (
            <div key={blog.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="text-2xl font-semibold mb-2">{blog.title}</h2>
                <p className="text-gray-600 mb-4">{blog.excerpt}</p>
                <Link
                  to={`/blogs/${blog.id}`}
                  className="text-blue-500 hover:underline font-semibold"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* View All Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/blogs" // Redirect to the main Blogs page
            className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-600"
          >
            View All Blogs
          </Link>
        </div>
      </div>

    </div>
  );
};

export default EventBlogs;
