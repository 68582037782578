import React, { useState, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";


const Market = () => {
  const [industryLeaders, setIndustryLeaders] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  // useCallback to memoize the fetchData function and prevent unnecessary re-creations
  const fetchData = useCallback(async () => {
    try {
      const response = await new Promise((resolve) =>
        setTimeout(() => {
          resolve([
            { id: 1, name: "M-BLock Market", subName: "Greater Kailash-1,Greater Kailash, New Delhi, Delhi 110001", photo: "GREATER-KAILASH.webp", url: "/mblockmkt", contact: "Contact us at +91-9818347406" },
            { id: 2, name: "Lajpat Nagar Market", subName: "Block J, Lajpat Nagar II, Lajpat Nagar, New Delhi, Delhi 110024", photo: "Lajpatnagar.avif", url: "/lajpatmkt", contact: "Contact us at +91-9818347406" },
            { id: 3, name: "Khan Market", subName: "Khan Market, Rabindra Nagar, New Delhi, Delhi 110003", photo: "khan-market.jpg", url: "/khanmkt", contact: "Contact us at +91-9818347406" },
            { id: 4, name: "Sarojini Market", subName: "Sarojini Nagar, New Delhi, Delhi 110023", photo: "Sarojini.jpg", url: "/sarojinimkt", contact: "Contact us at +91-9818347406" },
            { id: 5, name: "Rajouri Garden Market", subName: "Block J, Rajouri Garden,New Delhi, Delhi, 110027", photo: "Rajouri Garden Market.jpg", url: "/rajourigardenmarket", contact: "Contact us at +91-9818347406" },
            { id: 6, name: "Chandni Chowk Market", subName: "Sis Ganj Guru Dwara, Chhippy Wada, Chandni Chowk, Delhi, 110006" , photo: "CHANDANICHAWK.jpeg" , url: "/chandnichowkmkt", contact: "Contact us at +91-9818347406" }
          ]);
        }, 300) // Shorten delay for faster loading
      );

      setIndustryLeaders(response);
      setLoading(false); // Stop loading once data is fetched
    } catch (error) {
      console.error("Error fetching industry leaders:", error);
      setLoading(false); // Stop loading even on error
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const categoryCards = useCallback(() => {
    return industryLeaders.map((leader) => (
      <Link
        key={leader.id}
        to={leader.url}
        className="block overflow-hidden shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl rounded-lg"
      >
        <img
          src={leader.photo}
          alt={leader.name}
          className="w-full h-32 md:h-40 object-cover rounded-t-lg" // Decreased height
          loading="lazy"
        />
        <div className="p-2 bg-slate-100"> {/* Decreased padding */}
          <h3 className="text-sm md:text-base font-bold text-black">{leader.name}</h3> {/* Adjusted text size */}
          <p className="text-xs md:text-sm font-semibold text-gray-600">{leader.subName}</p> {/* Adjusted text size */}
          {/* <p className="text-xs text-gray-500 mt-1">{leader.contact}</p>  */}
        </div>
      </Link>
    ));
  }, [industryLeaders]);

  return (
    <div>
        <Navbar />
    <div className="bg-white text-black p-10 w-full mt-10">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold">Explore Markets 💫🔎</h2>
        <p className="text-gray-600">Choose from the top categories
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {loading ? (
          <p className="text-sm text-gray-500 text-center">Loading categories...</p>
        ) : (
          categoryCards() // Use the memoized component rendering
        )}
      </div>
    </div>
    
    <Footer />
    </div>
  );
};

export default React.memo(Market);
